import {apiPlugin, storyblokInit} from '@storyblok/js'

import {RESOLVE_RELATIONS, STORYBLOK_TOKEN} from '@/common/config'

export const {storyblokApi} = (() => {
  const {storyblokApi} = storyblokInit({
    accessToken: STORYBLOK_TOKEN,
    apiOptions: {
      cache: {type: 'memory'},
    },
    use: [apiPlugin],
  })

  if (!storyblokApi) throw Error('storyblokApi undefined')

  return {storyblokApi}
})()

export const getStory = async (slug, params) => {
  const {data} = await storyblokApi.getStory(slug, {
    version: 'draft',
    resolve_relations: RESOLVE_RELATIONS,
    ...params,
  })
  const {story} = data
  return story
}

export async function getBlogPosts(params) {
  const {data} = await storyblokApi.get('cdn/stories/', {
    version: 'draft',
    resolve_relations: RESOLVE_RELATIONS,
    ...params,
  })

  return data.stories.map((story) => {
    return {full_slug: story.full_slug, uuid: story.uuid, content: story.content}
  })
}
