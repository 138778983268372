/* eslint-disable no-warning-comments */
/* eslint-disable no-console */
import React, {useCallback, useEffect, useRef, useState} from 'react'

import {RESOLVE_RELATIONS, utils} from '@/common/config'
import {getBlogPosts, getStory} from '@/libs/storyblok'
import BlogList from '@/templates/BlogList'
// import {useStoryblokState} from 'gatsby-source-storyblok'
import BlogPost from '@/templates/BlogPost'
import Guide from '@/templates/Guide'
import Layout from '@/templates/Layout'
import Page from '@/templates/Page'

const hasArticlesLayout = (story) =>
  story.content.category && story.content.category.content.articlesLayout

const getSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search)
  const id = searchParams.get('_storyblok') || searchParams.get('_storyblok_published')
  const language = searchParams.get('_storyblok_lang') || 'default'
  const fullslug = searchParams.get('path') || 'index'
  const slug = fullslug.replace(language, '')

  if (!id) throw Error('Story ID not found.')

  const isPublishedPreview = !!searchParams.get('_storyblok_published')
  const template = searchParams.get('_storyblok_c')
  const params = {version: isPublishedPreview ? 'published' : 'draft', language}

  return {id, slug, template, params}
}

const injectArticleLayout = async ({template, params, story}) => {
  if (utils.isBlogPost(template) && hasArticlesLayout(story)) {
    const layout = await getStory(story.content.category.content.articlesLayout, {
      ...params,
      find_by: 'uuid',
    })
    story.content.category.content.articlesLayout = layout
    return layout
  }
}

const StoryblokPreview = () => {
  const [story, setStory] = useState()
  const [blogposts, setBlogPosts] = useState()
  const [template, setTemplate] = useState()

  const articleLayout = useRef()

  const initEventListeners = useCallback(async function () {
    const StoryblokBridge = window.StoryblokBridge

    if (typeof StoryblokBridge !== 'undefined') {
      const storyblokInstance = new StoryblokBridge({
        resolveRelations: RESOLVE_RELATIONS,
        preventClicks: true,
      })

      storyblokInstance.on(['published'], () => window.location.reload())

      storyblokInstance.on('input', async ({story}) => {
        const {template, params} = getSearchParams()
        if (hasArticlesLayout(story)) {
          const articlesLayoutUuid = story.content.category.content.articlesLayout
          if (articlesLayoutUuid !== articleLayout.current.uuid) {
            articleLayout.current = await injectArticleLayout({template, params, story})
          } else {
            story.content.category.content.articlesLayout = articleLayout.current
          }
        }
        setStory(story)
      })

      try {
        const {id, slug, template, params} = getSearchParams()

        if (!id) throw Error('Story ID not found.')

        const story = await getStory(slug, params)

        if (utils.isBlogListTemplate(template)) {
          const filters =
            template === 'blog-landing'
              ? [{component: {like: 'ressources-*'}}]
              : [
                  {category: {in: story.uuid}},
                  {author: {in: story.uuid}},
                  {tags: {any_in_array: story.uuid}},
                ]

          const posts = await getBlogPosts({
            ...params,
            filter_query: {__or: filters},
          })

          setBlogPosts(posts)
        } else {
          const posts = await getBlogPosts({
            ...params,
            filter_query: {__or: [{component: {like: 'ressources-*'}}]},
          })
          setBlogPosts(posts)
        }

        articleLayout.current = await injectArticleLayout({template, params, story})

        setStory(story)
        setTemplate(template)
      } catch (error) {
        console.error(error)
      }
    }
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const existingScript = document.getElementById('storyblokBridge')

      if (!existingScript) {
        const script = document.createElement('script')
        script.src = 'https://app.storyblok.com/f/storyblok-v2-latest.js'
        script.id = 'storyblokBridge'
        document.body.appendChild(script)
        script.onload = () => initEventListeners()
      } else {
        initEventListeners()
      }
    }
  }, [initEventListeners])

  if (!story || !template) return null

  if (template === 'ressources-guide') {
    return <Guide {...story} />
  } else if (utils.isBlogPost(template)) {
    return <BlogPost {...story} />
  } else if (utils.isBlogListTemplate(template)) {
    return <BlogList {...story} posts={blogposts} />
  } else if (template === 'layout') {
    return <Layout {...story} isPreview posts={blogposts} />
  } else {
    return <Page {...story} posts={blogposts} />
  }
}

export default StoryblokPreview
